import React from "react"
import classnames from "classnames"
import { graphql } from 'gatsby'
import Img from "gatsby-image"

import { Layout, SEO, Container } from "../components"
import styles from "./index.module.scss"
import receiverLogoSrc from "../images/buy-now/receiver-2-white-logo@2x.png"
import { isMobile } from "../utils/responsive"
import { getContentFromAllFiles } from "../utils/content"
import { pageTypes } from "../types"

const BuyNowPage = ({ data, pageContext: { locale } }) => {
  const { 
    title,
    description,
    humbleBumbleSrc,
    buyLinks,
    alsoTitle
  } = getContentFromAllFiles(data, 'buyNow');
  return (
    <Layout className={styles.buyNow} locale={locale} content={getContentFromAllFiles(data, 'site')}>
      <SEO title={`${title} | Receiver 2`} description={description} path="/buy-now/" lang={locale} />
      <Container className={classnames(styles.container, styles.buynowContainer)}>
        <div className={styles.intro}>
          <img src={receiverLogoSrc} alt="Receiver 2 logo" className={styles.buyNowLogo} />
          <iframe 
            className={styles.humbleBumble}
            width="550"
            height="255"
            scrolling={isMobile() ? "yes" : "no"}
            frameBorder="0"
            title={title}
            allowtransparency="true"
            src={humbleBumbleSrc}
          />
          <hr className={styles.divider} />
          <div className={styles.availableOn}>
            <h2>{alsoTitle}</h2>
            <ul>
            {
              buyLinks.map((logo, index) => (
                <li key={`link_${index}`}>
                  <a
                    href={logo.link}
                    title={title}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <Img 
                      className={styles.availableOnImg}
                      fixed={logo.imgSrc.childImageSharp.fixed}
                      alt={title}
                    />
                  </a>
                </li>
              ))
            }
            </ul>
          </div>
        </div>
      </Container>
    </Layout>
  )
}

export const pageQuery = graphql`
  query buyNowPageQuery($locale: String) {
    allFile(filter: {relativeDirectory: {eq: $locale}}) {
      edges {
        node {
          name
          childMarkdownRemark {
            frontmatter {
              title
              description
              humbleBumbleSrc
              alsoTitle
              buyLinks {
                imgSrc {
                  childImageSharp {
                    fixed {
                      ...GatsbyImageSharpFixed
                    }
                  }
                }
                link
                title
              }
            }
            ...Site
          }
        }
      }
    }
  }
`

BuyNowPage.propTypes = pageTypes

export default BuyNowPage
